<template>
  <div>
    <v-data-table :headers="headers" :items="items" dense :search="search">
      <template v-slot:top>
        <v-toolbar flat>
          <v-row class="mt-2">
            <v-col cols="12" sm="12" md="8" lg="8">
              <v-text-field
                flat
                dense
                outlined
                background-color="white"
                v-model="search"
                placeholder="search"
                append-icon="mdi-magnify"
                single-line
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="4" lg="4" class="text-right">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="success"
                    v-bind="attrs"
                    v-on="on"
                    dark
                    rounded
                    @click="openDialog({}, 0)"
                    class="mr-2"
                  >
                    <v-icon>
                      mdi-plus
                    </v-icon>
                  </v-btn>
                </template>
                <span>Create</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-toolbar>
      </template>
      <template v-slot:item.no="{ item }">
        {{ items.findIndex(x => x == item) + 1 }}
      </template>
      <template v-slot:item.action="{ item }">
        <v-btn color="success" @click="openDialog(item, 1)">
          update
        </v-btn>
      </template>
      <template v-slot:no-data>
        <v-btn color="primary" @click="initialize">
          Reset
        </v-btn>
      </template>
    </v-data-table>
    <create-template
      :dialog="dialog"
      :form="form"
      @save="saveTemplate"
      @close="close"
    ></create-template>
  </div>
</template>

<script>
import CreateTemplate from "./CreateTemplate.vue";
export default {
  name: "check-balance-template",
  components: {
    CreateTemplate,
  },
  data: () => ({
    items: [],
    search: "",
    defaultForm: {
      id: 0,
      name: "",
      remark: "",
    },
    form: {},
    dialog: false,
    headers: [
      {
        text: "No",
        align: "center",
        sortable: false,
        value: "no",
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        text: "Name",
        value: "name",
        sortable: false,
        divider: true,
        align: "center",
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        text: "Remark",
        value: "remark",
        sortable: false,
        divider: true,
        align: "center",
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        text: "Action",
        value: "action",
        sortable: false,
        divider: true,
        align: "center",
        class: "light-blue lighten-2 font-weight-black",
      },
    ],
  }),

  created() {
    this.initialize();
  },

  methods: {
    initialize() {
      this.$store.commit("SET_LOADER", true);
      this.$store
        .dispatch("checkBalance/getTemplate", this.date)
        .then(response => {
          this.items = response.data;
          this.$store.commit("SET_LOADER", false);
        })
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
    openDialog(item, type) {
      if (type == 1) {
        this.form = item;
      } else {
        this.form = Object.assign({}, this.defaultForm);
      }
      this.dialog = true;
    },
    close() {
      this.dialog = false;
    },
    saveTemplate(item) {
      this.$store.commit("SET_LOADER", true);
      this.$store
        .dispatch("checkBalance/saveTemplate", item)
        .then(() => this.$store.commit("SET_LOADER", false))
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
  },
};
</script>

<style></style>
